/* prettier-ignore-file */

// types
import { Helpline, TOPIC_TYPE } from "@/types";

export const helplines: Helpline[] = [
  {
    countryI18nKey: "Afghanistan",
    countryCode: "AF",
    name: "Befrienders Worldwide",
    description: "For immediate mental health and crisis support",
    url: "https://befrienders.org/",
    displayUrl: "http://www.befrienders.org/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Albania",
    countryCode: "AL",
    name: "alo116",
    description: "For children facing violence and abuse",
    url: "https://alo116.al/",
    displayUrl: "www.alo116.al/",
    phone: "116 111",
    displayNumber: "116-111",
    formattedCallNumber: "+355116111",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "AntiguaAndBarbuda",
    countryCode: "AG",
    name: "Antigua & Barbuda Support and Referral Centre",
    description: "For victims of gender based violence",
    url: "https://genderaffairs.gov.ag/support-and-referral-centre/",
    displayUrl: "www.genderaffairs.gov.ag/support-and-referral-centre/",
    phone: "(268) 463-5555",
    displayNumber: "268-463-5555",
    formattedCallNumber: "+12684635555",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Argentina",
    countryCode: "AR",
    name: "Suicide Assistance Center",
    description: "Para personas que enfrentan problemas de salud mental y crisis",
    url: "https://www.asistenciaalsuicida.org.ar/ayuda",
    displayUrl: "www.asistenciaalsuicida.org.ar/ayuda",
    phone: "135",
    displayNumber: "135",
    formattedCallNumber: "+54135",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Argentina",
    countryCode: "AR",
    name: "An Anonymous Friend",
    description: "Para personas luchando con la salud mental y crisis",
    url: "http://www.sosunamigoanonimo.com.ar/que-hacemos/",
    displayUrl: "www.sosunamigoanonimo.com.ar/que-hacemos/",
    phone: "(+5411)5263-0583",
    displayNumber: "541-152-630-583",
    formattedCallNumber: "+541152630583",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Argentina",
    countryCode: "AR",
    name: "Line 102",
    description: "Para niños y adolescentes que experimentan violación de derechos a ser escuchados y/o reportar",
    url: "https://www.argentina.gob.ar/desarrollosocial/linea102",
    displayUrl: "www.argentina.gob.ar/desarrollosocial/linea102",
    phone: "102",
    displayNumber: "102",
    formattedCallNumber: "+54102",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, WhatsApp",
    identity: "youth",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Argentina",
    countryCode: "AR",
    name: "Line 144",
    description: "Cuidado y consejo para mujeres y LGBTQI+ en situaciones de violencia de género",
    url: "https://www.argentina.gob.ar/node/proteccion-contra-toda-forma-de-violencia",
    displayUrl: "www.argentina.gob.ar/node/proteccion-contra-toda-forma-de-violencia",
    phone: "144",
    displayNumber: "144",
    formattedCallNumber: "+54144",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "WhatsApp",
    identity: "adults, LGBTQ+",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Argentina",
    countryCode: "AR",
    name: "Line 137",
    description: "Para cuidado y asistencia a víctimas de violencia familiar y/o sexual",
    url: "https://www.argentina.gob.ar/justicia/violencia-familiar-sexual",
    displayUrl: "www.argentina.gob.ar/justicia/violencia-familiar-sexual",
    phone: "137",
    displayNumber: "137",
    formattedCallNumber: "+54137",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Armenia",
    countryCode: "AM",
    name: "Trust Social Work",
    description: "For persons struggling with mental health crisis",
    url: "https://www.bros.global/copy-of-argentina-1",
    displayUrl: "www.bros.global/copy-of-argentina-1",
    phone: "(2) 538194",
    displayNumber: "253-819-4",
    formattedCallNumber: "+3742538194",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Aruba",
    countryCode: "AW",
    name: "131 Gratis",
    description: "",
    url: "https://131.aw/",
    displayUrl: "www.131.aw/",
    phone: "131",
    displayNumber: "131",
    formattedCallNumber: "+297131",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Australia",
    countryCode: "AU",
    name: "Lifeline Australia",
    description: "For immediate mental health and crisis support",
    url: "http://www.lifeline.org.au",
    displayUrl: "www.lifeline.org.au",
    phone: "131114",
    displayNumber: "131-114",
    formattedCallNumber: "+61131114",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Australia",
    countryCode: "AU",
    name: "Kids Helpline",
    description: "For immediate mental health support",
    url: "http://www.kidshelp.com.au",
    displayUrl: "www.kidshelp.com.au",
    phone: "1800551800",
    displayNumber: "180-055-180-0",
    formattedCallNumber: "+611800551800",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Australia",
    countryCode: "AU",
    name: "National Alcohol and Other Drug Hotline",
    description: "Confidential support for people struggling with addiction",
    url: "https://www.health.gov.au/our-work/drug-help#:~:text=National%20Alcohol%20and%20Other%20Drug%20Hotline&text=This%20hotline%20provides%20confidential%20support,week%20on%201800%20250%20015.",
    displayUrl: "www.health.gov.au/our-work/drug-help#:~:text=National%20Alcohol%20and%20Other%20Drug%20Hotline&text=This%20hotline%20provides%20confidential%20support,week%20on%201800%20250%20015.",
    phone: "1800250015",
    displayNumber: "180-025-001-5",
    formattedCallNumber: "+611800250015",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Australia",
    countryCode: "AU",
    name: "The Butterfly Organization",
    description: "For individuals struggling with eating disorders or body image issues",
    url: "https://butterfly.org.au/get-support/helpline/",
    displayUrl: "www.butterfly.org.au/get-support/helpline/",
    phone: "1800334673",
    displayNumber: "180-033-467-3",
    formattedCallNumber: "+611800334673",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: false,
  },
  {
    countryI18nKey: "Austria",
    countryCode: "AT",
    name: "Rat auf drat",
    description: "Telefonische Beratung für Kinder und Jugendliche zur psychischen Gesundheit",
    url: "https://www.rataufdraht.at/",
    displayUrl: "www.rataufdraht.at/",
    phone: "147",
    displayNumber: "147",
    formattedCallNumber: "+43147",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Austria",
    countryCode: "AT",
    name: "Telefon Seelsorge",
    description: "Telefonische Beratung für Personen in psychischer Not",
    url: "https://www.telefonseelsorge.at/",
    displayUrl: "www.telefonseelsorge.at/",
    phone: "142",
    displayNumber: "142",
    formattedCallNumber: "+43142",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Azerbaijan",
    countryCode: "AZ",
    name: "Azerbaijan Child Helpline Service",
    description: "For children facing violence and abuse",
    url: "https://childhelpline.az/index.php/az/",
    displayUrl: "www.childhelpline.az/index.php/az/",
    phone: "116111",
    displayNumber: "116111",
    formattedCallNumber: "+994116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bahamas",
    countryCode: "BS",
    name: "National Hotline for Crisis Intervention",
    description: "For immediate mental health and crisis support",
    url: "http://bit.ly/3vVw0Ox",
    displayUrl: "www.bit.ly/3vVw0Ox",
    phone: "(242) 322-2763",
    displayNumber: "242-322-2763",
    formattedCallNumber: "+12423222763",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bahamas",
    countryCode: "BS",
    name: "The Bahamas Crisis Centre",
    description: "For immediate mental health and crisis support",
    url: "https://www.facebook.com/tbcc242/",
    displayUrl: "https://www.facebook.com/tbcc242/",
    phone: "(242) 328-0922",
    displayNumber: "242-328-0922",
    formattedCallNumber: "+12423280922",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bahrain",
    countryCode: "BH",
    name: "Child Helpline Service",
    description: "For children facing violence and abuse",
    url: "https://www.social.gov.bh/website/home/subservices_sections/18?lang=en",
    displayUrl: "www.social.gov.bh/website/home/subservices_sections/18?lang=en",
    phone: "80008001",
    displayNumber: "800-080-01",
    formattedCallNumber: "+97380008001",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bangladesh",
    countryCode: "BD",
    name: "Vent - Crisis hotline service",
    description: "For immediate mental health and crisis support",
    url: "https://www.mindspacebd.com/",
    displayUrl: "www.mindspacebd.com/",
    phone: "09-678-678-778",
    displayNumber: "09-678-678-778",
    formattedCallNumber: "+88009678678778",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Barbados",
    countryCode: "BB",
    name: "Advocates Against Domestic Abuse",
    description: "For victims of domestic violence",
    url: "https://www.facebook.com/people/Advocates-Against-Domestic-Abuse/100064546008282/",
    displayUrl: "https://www.facebook.com/people/Advocates-Against-Domestic-Abuse/100064546008282/",
    phone: "(246) 432-2873",
    displayNumber: "246-432-2873",
    formattedCallNumber: "+12464322873",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Belarus",
    countryCode: "BY",
    name: "MOKU",
    description: "For immediate mental health and crisis support",
    url: "https://mokc.by/",
    displayUrl: "www.mokc.by/",
    phone: "+375 (29) 101-73-73",
    displayNumber: "+375 (29) 101-73-73",
    formattedCallNumber: "+375291017373",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Belgium",
    countryCode: "BE",
    name: "Suicide Line 1813",
    description: "Anonymous support for individuals experiencing mental health crisis",
    url: "https://www.zelfmoord1813.be",
    displayUrl: "www.zelfmoord1813.be",
    phone: "1813",
    displayNumber: "181-3",
    formattedCallNumber: "+321813",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, mail, self-help resources",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Belgium",
    countryCode: "BE",
    name: "Suicide Prevention Centre",
    description: "For individuals experiencing mental health crisis",
    url: "https://www.preventionsuicide.be",
    displayUrl: "www.preventionsuicide.be",
    phone: "080032123",
    displayNumber: "080-032-123",
    formattedCallNumber: "+32080032123",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Belgium",
    countryCode: "BE",
    name: "Tele-onthaal",
    description: "For individuals looking for mental health support",
    url: "https://www.tele-onthaal.be",
    displayUrl: "www.tele-onthaal.be",
    phone: "106",
    displayNumber: "106",
    formattedCallNumber: "+32106",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Belgium",
    countryCode: "BE",
    name: "de druglin",
    description: "For individuals who have questions about substance use and addiction",
    url: "https://www.druglijn.be",
    displayUrl: "www.druglijn.be",
    phone: "078151020",
    displayNumber: "078-151-020",
    formattedCallNumber: "+32078151020",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, mail",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bhutan",
    countryCode: "BT",
    name: "Mental Health Hotlines",
    description: "For immediate mental health and crisis support",
    url: "https://www.moh.gov.bt/mental-health-hotlines/",
    displayUrl: "//www.moh.gov.bt/mental-health-hotlines/",
    phone: "112",
    displayNumber: "112",
    formattedCallNumber: "+975112",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "BosniaAndHerzegovina",
    countryCode: "BA",
    name: "Plavi Telefon",
    description: "For immediate mental health and crisis support",
    url: "https://www.plavitelefon.ba/",
    displayUrl: "www.plavitelefon.ba/",
    phone: "080 05 03 05",
    displayNumber: "080-05-03-05",
    formattedCallNumber: "+387080050305",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Botswana",
    countryCode: "BW",
    name: "Lifeline FTMTB",
    description: "For immediate mental health and crisis support",
    url: "https://www.facebook.com/people/Lifeline-FTMTB-Botswana/100064900805554/",
    displayUrl: "www.facebook.com/people/Lifeline-FTMTB-Botswana/100064900805554/",
    phone: "+267 75 527 590",
    displayNumber: "+267-75-527-590",
    formattedCallNumber: "+26775527590",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Botswana",
    countryCode: "BW",
    name: "Childline Botswana Trust",
    description: "For children facing violence and abuse",
    url: "https://www.facebook.com/ChildlineBotswanaTrust/",
    displayUrl: "www.facebook.com/ChildlineBotswanaTrust/",
    phone: "+267 390 0900",
    displayNumber: "+267-390-0900",
    formattedCallNumber: "+2673900900",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Brazil",
    countryCode: "BR",
    name: "Centre for the Appreciation of Life",
    description: "For individual seeking emotional support and crisis support",
    url: "https://www.cvv.org.br",
    displayUrl: "www.cvv.org.br",
    phone: "188",
    displayNumber: "188",
    formattedCallNumber: "+55188",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Brazil",
    countryCode: "BR",
    name: "Alcoholics Anonymous",
    description: "For individuals seeking support with alcholism",
    url: "https://www.aa.org.br",
    displayUrl: "www.aa.org.br",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bulgaria",
    countryCode: "BG",
    name: "National Children's Helpline",
    description: "For children facing violence and abuse",
    url: "https://www.116111.bg/",
    displayUrl: "www.116111.bg/",
    phone: "116 111",
    displayNumber: "116-111",
    formattedCallNumber: "+359116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Bulgaria",
    countryCode: "BG",
    name: "The National Hotline",
    description: "For victims of domestic violence and human trafficking",
    url: "http://animusassociation.org/programi-uslugi/goreshta-linia/",
    displayUrl: "www.animusassociation.org/programi-uslugi/goreshta-linia/",
    phone: "0800 1 8676",
    displayNumber: "0800-1-8676",
    formattedCallNumber: "+359080018676",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Burundi",
    countryCode: "BI",
    name: "SOS Women in Distress Network",
    description: "For victims of domestic violence and human trafficking",
    url: "http://sosfed.org/",
    displayUrl: "www.sosfed.org/",
    phone: "+257 753 317 27",
    displayNumber: "+257-753-317-27",
    formattedCallNumber: "+25775331727",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Cambodia",
    countryCode: "KH",
    name: "Child Helpline Cambodia",
    description: "For children facing violence and abuse",
    url: "https://www.facebook.com/chc1280/",
    displayUrl: "www.facebook.com/chc1280/",
    phone: "1280",
    displayNumber: "1280",
    formattedCallNumber: "+8551280",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Canada",
    countryCode: "CA",
    name: "Hope for Wellness Helpline",
    description: "For indigenous people seeking mental health support",
    url: "https://www.hopeforwellness.ca",
    displayUrl: "www.hopeforwellness.ca",
    phone: "18552423310",
    displayNumber: "185-524-233-10",
    formattedCallNumber: "+18552423310",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Canada",
    countryCode: "CA",
    name: "Kids Help Phone",
    description: "For individuals needing emotional and mental health support",
    url: "https://kidshelpphone.ca/urgent-help",
    displayUrl: "www.kidshelpphone.ca/urgent-help",
    phone: "18006686868",
    displayNumber: "1800-668-68-68",
    formattedCallNumber: "+18006686868",
    formattedTextingNumber: "+1686868",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Canada",
    countryCode: "CA",
    name: "Talk Suicide Canada",
    description: "For individuals in crisis",
    url: "https://talksuicide.ca/",
    displayUrl: "www.talksuicide.ca/",
    phone: "988",
    displayNumber: "988",
    formattedCallNumber: "+1988",
    formattedTextingNumber: "+1988",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Canada",
    countryCode: "CA",
    name: "Drug Rehab Services Canada",
    description: "For individuals looking for dug and alcohol addiction resources",
    url: "https://www.drugrehab.ca",
    displayUrl: "www.drugrehab.ca",
    phone: "18772543348",
    displayNumber: "187-725-433-48",
    formattedCallNumber: "+18772543348",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "CaymanIslands",
    countryCode: "KY",
    name: "CayMIND",
    description: "",
    url: "https://caymind.ky/",
    displayUrl: "https://www.caymind.ky/",
    phone: "1-800-534-6463",
    displayNumber: "1-800-534-6463",
    formattedCallNumber: "+134518005346463",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Chile",
    countryCode: "CL",
    name: "Psicologos X Chile",
    description: "Servicio que provee apoyo emocional y de crisis gratuito y confidencial",
    url: "https://psicologosxchile.cl",
    displayUrl: "www.psicologosxchile.cl",
    phone: "56967613603",
    displayNumber: "569-676-136-03",
    formattedCallNumber: "+56967613603",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Chile",
    countryCode: "CL",
    name: "Ministry of Health (Government Services)",
    description: "Apoyo inmediato para la salud mental y crisis",
    url: "https://www.gob.cl/noticias/no-estas-solo-no-estas-sola-ministerio-de-salud-lanzo-fono-4141-de-prevencion-del-suicidio/",
    displayUrl: "www.gob.cl/noticias/no-estas-solo-no-estas-sola-ministerio-de-salud-lanzo-fono-4141-de-prevencion-del-suicidio/",
    phone: "*4141",
    displayNumber: "414-1",
    formattedCallNumber: "+564141",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Chile",
    countryCode: "CL",
    name: "Línea Libre: Fundación Para la Confianza",
    description: "Para niños y adolescentes que necesitan apoyo en salud mental",
    url: "https://www.linealibre.cl",
    displayUrl: "www.linealibre.cl",
    phone: "1515",
    displayNumber: "151-5",
    formattedCallNumber: "+561515",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "China",
    countryCode: "CN",
    name: "Lifeline",
    description: "For individuals seeking emotional and crisis support",
    url: "https://lifelinechina.org",
    displayUrl: "www.lifelinechina.org",
    phone: "4008211215",
    displayNumber: "400-821-121-5",
    formattedCallNumber: "+864008211215",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Colombia",
    countryCode: "CO",
    name: "SergioUrrego Fundacion",
    description: "Para individuos luchando con salud emocional y mental",
    url: "https://www.sergiourrego.org/",
    displayUrl: "www.sergiourrego.org/",
    phone: "3117668666",
    displayNumber: "3117668666",
    formattedCallNumber: "+573117668666",
    formattedTextingNumber: "+573117668666",
    availability: "24/7",
    modality: "phone, whatsapp",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Colombia",
    countryCode: "CO",
    name: "Te Guio",
    description: "Para niños y adolescentes que necesitan apoyo en salud mental",
    url: "https://www.teguiocolombia.org/",
    displayUrl: "www.teguiocolombia.org/",
    phone: "018005190690",
    displayNumber: "018005190690",
    formattedCallNumber: "+5718005190690",
    formattedTextingNumber: "+573148210435",
    availability: "",
    modality: "phone, whatsapp",
    identity: "youth",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "CostaRica",
    countryCode: "CR",
    name: "Aquí Estoy (Here I am)",
    description: "Para individuos luchando con salud emocional y mental",
    url: "https://aquiestoy.cr/",
    displayUrl: "https://aquiestoy.cr/",
    phone: "8002737869",
    displayNumber: "800-273-7869",
    formattedCallNumber: "+5068002737869",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Croatia",
    countryCode: "HR",
    name: "Hrabri telefon (Brave Phone)",
    description: "For children and teenagers looking for support",
    url: "https://hrabritelefon.hr/#",
    displayUrl: "www.hrabritelefon.hr/#",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+385116111",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "CzechRepublic",
    countryCode: "CZ",
    name: "Linka bezpeči",
    description: "For children and adults under 26 years of age struggling with emotional health",
    url: "https://www.linkabezpeci.cz/pomoc",
    displayUrl: "www.linkabezpeci.cz/pomoc",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+420116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "CzechRepublic",
    countryCode: "CZ",
    name: "Modrá linka",
    description: "For individuals struggling with emotional and mental health crisis",
    url: "https://www.modralinka.cz/sluzby/linka-duvery",
    displayUrl: "www.modralinka.cz/sluzby/linka-duvery",
    phone: "608902410",
    displayNumber: "608-902-410",
    formattedCallNumber: "+420608902410",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Denmark",
    countryCode: "DK",
    name: "Livslinien",
    description: "For individuals struggling with mental health crisis",
    url: "https://www.livslinien.dk",
    displayUrl: "www.livslinien.dk",
    phone: "70201201",
    displayNumber: "702-012-01",
    formattedCallNumber: "+4570201201",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Denmark",
    countryCode: "DK",
    name: "BørneTelefonen",
    description: "For individuals struggling with emotional health and mental health",
    url: "https://bornetelefonen.dk/ring",
    displayUrl: "www.bornetelefonen.dk/ring",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+45116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "DominicanRepublic",
    countryCode: "DO",
    name: "Linea Familiar",
    description: "Apoyo inmediato para la salud mental y crisis",
    url: "https://lineafamiliar.do/",
    displayUrl: "www.lineafamiliar.do/",
    phone: "809-636-3507",
    displayNumber: "809-636-3507",
    formattedCallNumber: "+18096363507",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ecuador",
    countryCode: "EC",
    name: "Befrienders International",
    description: "Apoyo inmediato para la salud mental y crisis",
    url: "https://befrienders.org/",
    displayUrl: "www.befrienders.org",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ecuador",
    countryCode: "EC",
    name: "Linea De Apoyo A Mujeres",
    description: "Apoyo inmediato para la salud mental y crisis",
    url: "https://www.facebook.com/lineadeapoyo/",
    displayUrl: "",
    phone: "+593 95 893 4487",
    displayNumber: "+593-95-893-4487",
    formattedCallNumber: "+593958934487",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ecuador",
    countryCode: "EC",
    name: "Anima EC",
    description: "Apoyo inmediato para la salud mental y crisis",
    url: "https://befrienders.org/find-support-now/anima-ec/?country=ec",
    displayUrl: "",
    phone: "+593 99 719 6911",
    displayNumber: "+593-99-719-6911",
    formattedCallNumber: "+593997196911",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Estonia",
    countryCode: "EE",
    name: "Ohvriabi kriisitelefon",
    description: "For immediate mental health and crisis support",
    url: "https://sotsiaalkindlustusamet.ee/abivajav-laps-ja-taiskasvanu/abi-vagivalla-ohvrile/ohvriabi-kriisitelefon-116006",
    displayUrl: "",
    phone: "116 006",
    displayNumber: "116-006",
    formattedCallNumber: "+372116006",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Estonia",
    countryCode: "EE",
    name: "Suicide Hotline",
    description: "For immediate mental health and crisis support",
    url: "https://www.eluliin.ee/",
    displayUrl: "www.eluliin.ee/",
    phone: " 655 8088",
    displayNumber: "655-8088",
    formattedCallNumber: "+3726558088",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Fiji",
    countryCode: "FJ",
    name: "Empower Pacific",
    description: "For immediate mental health and crisis support",
    url: "https://www.empowerpacific.com/",
    displayUrl: "https://www.empowerpacific.com/",
    phone: "5626",
    displayNumber: "5626",
    formattedCallNumber: "+6795626",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Finland",
    countryCode: "FI",
    name: "Mieli-jäsenyhdistystenverkkosivut",
    description: "A site for locating mental health support within specific locations in Finland",
    url: "https://www.mielenterveysseurat.fi",
    displayUrl: "www.mielenterveysseurat.fi",
    phone: "020391920",
    displayNumber: "0203-91920",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Finland",
    countryCode: "FI",
    name: "Kriisipuhelin",
    description: "For individuals struggling with mental health crisis",
    url: "https://mieli.fi/tukea-ja-apua/kriisipuhelin/",
    displayUrl: "www.mieli.fi/tukea-ja-apua/kriisipuhelin/",
    phone: "0925250111",
    displayNumber: "092-525-011-1",
    formattedCallNumber: "+3580925250111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "France",
    countryCode: "FR",
    name: "SOS-amitie",
    description: "Pour un soutien immédiat en matière de santé mentale et de crise",
    url: "https://www.sos-amitie.com",
    displayUrl: "www.sos-amitie.com",
    phone: "0972394050",
    displayNumber: "097-239-405-0",
    formattedCallNumber: "+330972394050",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "France",
    countryCode: "FR",
    name: "Suicide Ecoute",
    description: "Pour un soutien immédiat en matière de santé mentale et de crise",
    url: "https://www.suicide-ecoute.fr",
    displayUrl: "www.suicide-ecoute.fr",
    phone: "0145394000",
    displayNumber: "014-539-400-0",
    formattedCallNumber: "+330145394000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Gambia",
    countryCode: "GM",
    name: "Child Helpline Gambia",
    description: "For children facing violence and abuse",
    url: "",
    displayUrl: "",
    phone: "199",
    displayNumber: "199",
    formattedCallNumber: "+220199",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Georgia",
    countryCode: "GE",
    name: "Georgia Crisis & Access Line",
    description: "For individuals seeking mental health support",
    url: "https://dbhdd.georgia.gov/be-dbhdd/be-supported/mental-health-adults/adult-mental-health-crisis-services#:~:text=Georgia%20Crisis%20%26%20Access%20Line%3A%20(,for%20individuals%20to%20access%20services.",
    displayUrl: "",
    phone: "(800) 715-4225",
    displayNumber: "800-715-4225",
    formattedCallNumber: "+9958007154225",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Germany",
    countryCode: "DE",
    name: "TelefonSeelsorge",
    description: "Für Personen, die Unterstützung im Bereich der psychischen Gesundheit suchen",
    url: "https://www.telefonseelsorge.de/telefon/",
    displayUrl: "www.telefonseelsorge.de/telefon/",
    phone: "8001110111",
    displayNumber: "080-011-101-11",
    formattedCallNumber: "+4908001110111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Germany",
    countryCode: "DE",
    name: "Nummergegenkummer",
    description: "Für Kinder, Jugendliche und Eltern, die emotionale und Krisenunterstützung suchen",
    url: "https://www.nummergegenkummer.de",
    displayUrl: "www.nummergegenkummer.de",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+49116111",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Germany",
    countryCode: "DE",
    name: "Nummergegenkummer",
    description: "Für Eltern, die Unterstützung beim Wohlbefinden ihrer Kinder suchen",
    url: "https://www.nummergegenkummer.de",
    displayUrl: "www.nummergegenkummer.de",
    phone: "08001110550",
    displayNumber: "080-011-105-50",
    formattedCallNumber: "+4908001110550",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Germany",
    countryCode: "DE",
    name: "Krisenchat",
    description: "Für Personen, die Krisenunterstützung in Form von SMS suchen",
    url: "https://krisenchat.de/en",
    displayUrl: "www.krisenchat.de/en",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "+4915735998143",
    availability: "24/7",
    modality: "text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ghana",
    countryCode: "GH",
    name: "National Lifeline",
    description: "For individuals in mental health crisis",
    url: "https://groups.oist.jp/system/files/Ghana.pdf",
    displayUrl: "www.groups.oist.jp/system/files/Ghana.pdf",
    phone: "233244471279",
    displayNumber: "233-244-471-279",
    formattedCallNumber: "+233244471279",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Gibraltar",
    countryCode: "GI",
    name: "GibSams",
    description: "For individuals in mental health crisis",
    url: "https://gibsams.gi/",
    displayUrl: "www.gibsams.gi/",
    phone: "116 123",
    displayNumber: "116-123",
    formattedCallNumber: "+350116123",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Gibraltar",
    countryCode: "GI",
    name: "Drugwise Gibraltar",
    description: "For individuals struggling with drug addiction",
    url: "https://www.drugwise.gi/",
    displayUrl: "www.drugwise.gi/",
    phone: "+350 20048444",
    displayNumber: "200-484-44",
    formattedCallNumber: "+35020048444",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Gibraltar",
    countryCode: "GI",
    name: "Childline Gibraltar",
    description: "For individuals in mental health crisis",
    url: "https://childline.gi/",
    displayUrl: "www.childline.gi/",
    phone: "8008",
    displayNumber: "8008",
    formattedCallNumber: "+3508008",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Greece",
    countryCode: "GR",
    name: "Suicide Hotline - Kilmaka",
    description: "For individuals struggling with mental health crisis",
    url: "http://suicide-help.gr",
    displayUrl: "www.suicide-help.gr",
    phone: "1018",
    displayNumber: "101-8",
    formattedCallNumber: "+301018",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Greece",
    countryCode: "GR",
    name: "National Helpline for Children",
    description: "For children and youth in need of safety and support",
    url: "https://www.hamogelo.gr/gr/en/sos-1056/",
    displayUrl: "www.hamogelo.gr/gr/en/sos-1056/",
    phone: "1056",
    displayNumber: "105-6",
    formattedCallNumber: "+301056",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Greece",
    countryCode: "GR",
    name: "UNHCR - Helpline for LGBTQI+",
    description: "For LGBTQI+ individuals in need of support",
    url: "https://help.unhcr.org/greece/el/where-to-seek-help/emergency-services/",
    displayUrl: "www.help.unhcr.org/greece/el/where-to-seek-help/emergency-services/",
    phone: "11528",
    displayNumber: "115-28",
    formattedCallNumber: "+3011528",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: false,
  },
  {
    countryI18nKey: "Grenada",
    countryCode: "GD",
    name: "Sweet Water Foundation - Sexual Abuse Line",
    description: "For victims of domestic violence and human trafficking",
    url: "https://sweetwaterfoundation.ca/",
    displayUrl: "www.sweetwaterfoundation.ca/",
    phone: "(473) 407- 4445",
    displayNumber: "473-407-4445",
    formattedCallNumber: "+14734074445",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Guatemala",
    countryCode: "GT",
    name: "Women's Observatory",
    description: "Para mujeres que buscan apoyo ante la violencia física y psicológica",
    url: "",
    displayUrl: "",
    phone: "1572",
    displayNumber: "157-2",
    formattedCallNumber: "+5021572",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Guyana",
    countryCode: "GY",
    name: "Guyana Suicide Helpline",
    description: "For individuals experiencing mental health crisis",
    url: "https://www.guyanastandard.com/2019/07/29/24-hour-crisis-hotline-available-to-guyanese-public/?fbclid=IwAR2Jr9d607D7D598D1Q84q7mXDjRxuDoIR9S6eli5XSRw7raKa0X4XnVUBc",
    displayUrl: "www.guyanastandard.com/2019/07/29/24-hour-crisis-hotline-available-to-guyanese-public/?fbclid=IwAR2Jr9d607D7D598D1Q84q7mXDjRxuDoIR9S6eli5XSRw7raKa0X4XnVUBc",
    phone: "5922230001",
    displayNumber: "592-223-000-1",
    formattedCallNumber: "+5922230001",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Guyana",
    countryCode: "GY",
    name: "Inter-Agency Suicide Prevention Helpline",
    description: "For individuals experiencing mental health crisis",
    url: "https://www.facebook.com/profile.php?id=100066835621214",
    displayUrl: "www.facebook.com/profile.php?id=100066835621214",
    phone: "5926007896",
    displayNumber: "592-600-789-6",
    formattedCallNumber: "+5926007896",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Haiti",
    countryCode: "HT",
    name: "Asistans Sikolojik an IJANS",
    description: "For immediate mental health and crisis support",
    url: "",
    displayUrl: "",
    phone: "2919 9000",
    displayNumber: "2919 9000",
    formattedCallNumber: "+50929199000",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Honduras",
    countryCode: "HN",
    name: "Telefono de la Esperanza de Honduras",
    description: "Para individuos que buscan apoyo en salud mental",
    url: "https://www.facebook.com/telefonodelaesperanzahn/about",
    displayUrl: "www.facebook.com/telefonodelaesperanzahn/about",
    phone: "150",
    displayNumber: "150",
    formattedCallNumber: "+504150",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "HongKong",
    countryCode: "HK",
    name: "Emotional Support Hotline (The Samaritan Befrienders Hong Kong)",
    description: "For individuals seeking emotional support",
    url: "https://sbhk.org.hk/?page_id=32063&lang=en",
    displayUrl: "www.sbhk.org.hk/?page_id=32063&lang=en",
    phone: "23892222",
    displayNumber: "238-922-22",
    formattedCallNumber: "+85223892222",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "HongKong",
    countryCode: "HK",
    name: "Suicide Prevention Services",
    description: "For individuals seeking support for crisis",
    url: "https://www.sps.org.hk/",
    displayUrl: "www.sps.org.hk/",
    phone: "23820000",
    displayNumber: "238-200-00",
    formattedCallNumber: "+85223820000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Hungary",
    countryCode: "HU",
    name: "Kek Vonal",
    description: "For individuals seeking support for mental health",
    url: "https://kek-vonal.hu/",
    displayUrl: "www.kek-vonal.hu/",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+36116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Iceland",
    countryCode: "IS",
    name: "Raudi krossin Hjálparsíminn 1717",
    description: "For immediate mental health and crisis support",
    url: "https://www.raudikrossinn.is/verkefni/innanlandsverkefni/heilbrigdi-og-velferd/hjalparsiminn-1717-og-netspjallid/",
    displayUrl: "https://www.raudikrossinn.is/verkefni/innanlandsverkefni/heilbrigdi-og-velferd/hjalparsiminn-1717-og-netspjallid/",
    phone: "1717",
    displayNumber: "1717",
    formattedCallNumber: "+3541717",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "India",
    countryCode: "IN",
    name: "Vandrevala Foundation",
    description: "For individuals experiencing mental health crisis",
    url: "https://www.vandrevalafoundation.com/free-counseling",
    displayUrl: "www.vandrevalafoundation.com/free-counseling",
    phone: "919999666555",
    displayNumber: "919-999-666-555",
    formattedCallNumber: "+919999666555",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "India",
    countryCode: "IN",
    name: "1Life Crisis Support Suicide Prevention",
    description: "For individuals seeking suicide prevention and crisis support services",
    url: "https://1life.org.in/",
    displayUrl: "www.1life.org.in/",
    phone: "7893078930",
    displayNumber: "789-307-893-0",
    formattedCallNumber: "+917893078930",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Indonesia",
    countryCode: "ID",
    name: "LBH Apik Jakarta",
    description: "For victims of domestic violence and human trafficking",
    url: "",
    displayUrl: "",
    phone: "813888226699",
    displayNumber: "813-888-226-699",
    formattedCallNumber: "+62813888226699",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ireland",
    countryCode: "IE",
    name: "pieta",
    description: "For immediate mental health and crisis support",
    url: "https://www.pieta.ie/",
    displayUrl: "www.pieta.ie/",
    phone: "1800247247",
    displayNumber: "1800-247-247",
    formattedCallNumber: "'+441800247247",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ireland",
    countryCode: "IE",
    name: "Childline",
    description: "For children and youth in need of emotional support",
    url: "https://www.childline.ie/",
    displayUrl: "www.childline.ie/",
    phone: "1800666666",
    displayNumber: "180-066-666-6",
    formattedCallNumber: "+3531800666666",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ireland",
    countryCode: "IE",
    name: "Samaritans Ireland",
    description: "For individuals seeking mental health support",
    url: "https://www.samaritans.org/samaritans-ireland/",
    displayUrl: "www.samaritans.org/samaritans-ireland/",
    phone: "116123",
    displayNumber: "116-123",
    formattedCallNumber: "+353116123",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Israel",
    countryCode: "IL",
    name: "Sahar",
    description: "For individuals seeking mental health support",
    url: "https://sahar.org.il/",
    displayUrl: "www.sahar.org.il/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "whatsapp",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Israel",
    countryCode: "IL",
    name: "Eran",
    description: "For individuals experiencing mental health crisis",
    url: "https://www.eran.org.il/suicide-prevention/",
    displayUrl: "www.eran.org.il/suicide-prevention/",
    phone: "1201",
    displayNumber: "120-1",
    formattedCallNumber: "+9721201",
    formattedTextingNumber: "+972529993544",
    availability: "24/7",
    modality: "phone, chat, text",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Italy",
    countryCode: "IT",
    name: "TelefonoAmico",
    description: "For individuals experiencing mental health crisis",
    url: "https://telefonoamicocevita.it/",
    displayUrl: "www.telefonoamicocevita.it/",
    phone: "0299777",
    displayNumber: "0299-777",
    formattedCallNumber: "+390299777",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Jamaica",
    countryCode: "JM",
    name: "U-Matter Text Line",
    description: "For immediate mental health and crisis support",
    url: "https://jamaica.ureport.in/page/umatterprivacypolicy/",
    displayUrl: "www.jamaica.ureport.in/page/umatterprivacypolicy/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Jamaica",
    countryCode: "JM",
    name: "Suicide Prevention Hotline",
    description: "For immediate mental health and crisis support",
    url: "https://www.moh.gov.jm/mental-health-wellness/",
    displayUrl: "www.moh.gov.jm/mental-health-wellness/",
    phone: "888-639-5433",
    displayNumber: "888-639-5433",
    formattedCallNumber: "+18886395433",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Japan",
    countryCode: "JP",
    name: "LifeLink",
    description: "For individuals looking for crisis support",
    url: "https://www.lifelink.or.jp/inochisos/",
    displayUrl: "www.lifelink.or.jp/inochisos/",
    phone: "120061338",
    displayNumber: "120-061-338",
    formattedCallNumber: "+81120061338",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Japan",
    countryCode: "JP",
    name: "TellJP",
    description: "For individuals experiencing mental health crisis",
    url: "https://telljp.com/lifeline/",
    displayUrl: "www.telljp.com/lifeline/",
    phone: "357740992",
    displayNumber: "357-740-992",
    formattedCallNumber: "+81357740992",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Jordan",
    countryCode: "JO",
    name: "Jordanian Clinical Psychologists Association",
    description: "",
    url: "https://www.facebook.com/people/Jordanian-Clinical-Psychologists-Association/100040335405737/",
    displayUrl: "https://www.facebook.com/people/Jordanian-Clinical-Psychologists-Association/100040335405737/",
    phone: "+962 7 9786 5955",
    displayNumber: "9627-9786-5955",
    formattedCallNumber: "+962797865955",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Kazakhstan",
    countryCode: "KZ",
    name: "National Helpline for Children and Youth service",
    description: "For children experience mental health struggles",
    url: "",
    displayUrl: "",
    phone: "150",
    displayNumber: "150",
    formattedCallNumber: "+7150",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Kenya",
    countryCode: "KE",
    name: "BefriendersKenya",
    description: "For individuals seeking emotional support",
    url: "https://befrienders.org/",
    displayUrl: "http://www.befrienders.org/",
    phone: "254722178177",
    displayNumber: "254-722-178-177",
    formattedCallNumber: "+254722178177",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Latvia",
    countryCode: "LV",
    name: "Skalbes",
    description: "For individuals experiencing mental health crisis",
    url: "https://www.skalbes.lv/",
    displayUrl: "www.skalbes.lv/",
    phone: "116123",
    displayNumber: "116-123",
    formattedCallNumber: "+371116123",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Latvia",
    countryCode: "LV",
    name: "Child and Adolescent Hotline",
    description: "For children experience mental health struggles",
    url: "https://uzticibastalrunis.lv/par-mums/",
    displayUrl: "www.uzticibastalrunis.lv/par-mums/",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+371116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Lebanon",
    countryCode: "LB",
    name: "Emergency Safe Line",
    description: "For individuals seeking emotional support",
    url: "https://www.abaadmena.org/",
    displayUrl: "www.abaadmena.org/",
    phone: "81788178",
    displayNumber: "81-78-81-78",
    formattedCallNumber: "+96181788178",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Lebanon",
    countryCode: "LB",
    name: "The Embrace Lifeline",
    description: "For individuals seeking emotional support",
    url: "https://embracelebanon.org/",
    displayUrl: "www.embracelebanon.org/",
    phone: "1564",
    displayNumber: "1564",
    formattedCallNumber: "+9611564",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Libya",
    countryCode: "LY",
    name: "Libya Psychosocial Support Team",
    description: "For individuals seeking emotional support",
    url: "https://t.ly/7yDxP",
    displayUrl: "https://t.ly/7yDxP",
    phone: "1417",
    displayNumber: "1417",
    formattedCallNumber: "+2181417",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Lithuania",
    countryCode: "LT",
    name: "Jaunimo Linija",
    description: "For individuals seeking emotional support",
    url: "https://jaunimolinija.lt/",
    displayUrl: "www.jaunimolinija.lt/",
    phone: "880028888",
    displayNumber: "880-028-888",
    formattedCallNumber: "+370880028888",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Lithuania",
    countryCode: "LT",
    name: "Pagalbos Moterims Linija",
    description: "For women seeking mental health support",
    url: "https://pagalbosmoterimslinija.lt/psichologine-pagalba-telefonu/",
    displayUrl: "www.pagalbosmoterimslinija.lt/psichologine-pagalba-telefonu/",
    phone: "880066366",
    displayNumber: "880-066-366",
    formattedCallNumber: "+370880066366",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Luxembourg",
    countryCode: "LU",
    name: "SOS Distress",
    description: "For individuals seeking emotional support",
    url: "https://454545.lu/",
    displayUrl: "www.454545.lu/",
    phone: "454545",
    displayNumber: "454-545",
    formattedCallNumber: "+352454545",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Macao",
    countryCode: "MO",
    name: "Lifehope",
    description: "For immediate mental health and crisis support",
    url: "https://caritas.org.mo/",
    displayUrl: "https://www.caritas.org.mo/",
    phone: "+853 2852 5777",
    displayNumber: "+853-2852-5777",
    formattedCallNumber: "+85328525777",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malawi",
    countryCode: "MW",
    name: "Child Helpline",
    description: "For children facing violence and abuse",
    url: "https://yoneco.org/",
    displayUrl: "www.yoneco.org/",
    phone: "116",
    displayNumber: "116",
    formattedCallNumber: "+265116",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malawi",
    countryCode: "MW",
    name: "GBV Crisis Line",
    description: "For victims of domestic violence",
    url: "https://yoneco.org/",
    displayUrl: "www.yoneco.org/",
    phone: "5600",
    displayNumber: "5600",
    formattedCallNumber: "+2655600",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malawi",
    countryCode: "MW",
    name: "Drug & Substance Abuse Hotline",
    description: "For individuals struggling with drug addiction",
    url: "https://yoneco.org/",
    displayUrl: "www.yoneco.org/",
    phone: "6600",
    displayNumber: "6600",
    formattedCallNumber: "+2656600",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malawi",
    countryCode: "MW",
    name: "Youth Helpline",
    description: "For children facing violence and abuse",
    url: "https://yoneco.org/",
    displayUrl: "www.yoneco.org/",
    phone: "393",
    displayNumber: "393",
    formattedCallNumber: "+265393",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "Befrienders Kuala Lumpur",
    description: "For immediate mental health and crisis support",
    url: "https://www.befrienders.org.my/",
    displayUrl: "http://www.befrienders.my/",
    phone: "03-76272929",
    displayNumber: "03-76272929",
    formattedCallNumber: "+60376272929",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "Sage Centre",
    description: "For immediate mental health and crisis support",
    url: "https://www.sagecentre.co/",
    displayUrl: "www.sagecentre.co/",
    phone: "60123397121",
    displayNumber: "6012-339-7121",
    formattedCallNumber: "+60123397121",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "Buddy Bear Helpline",
    description: "For immediate mental health and crisis support",
    url: "www.humankind.my/",
    displayUrl: "https://www.humankind.my/",
    phone: "1800-18-2327",
    displayNumber: "1800-18-2327",
    formattedCallNumber: "+601800-18-2327",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "Miasa Malaysia",
    description: "For immediate mental health and crisis support",
    url: "www.miasa.org.my/",
    displayUrl: "https://miasa.org.my/",
    phone: "1800-180-066",
    displayNumber: "1800-180-066",
    formattedCallNumber: "+601800-180-066",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "Lifeline Malaysia",
    description: "For immediate mental health and crisis support",
    url: "www.lifeline.org.my/7995/",
    displayUrl: "https://lifeline.org.my/7995/",
    phone: "+603-42657995",
    displayNumber: "+603-42657995",
    formattedCallNumber: "+60342657995",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "Narcotics Anonymous Malaysia",
    description: "For individuals struggling with drug addiction",
    url: "https://www.namalaysia.my/",
    displayUrl: "www.namalaysia.my/",
    phone: "+60-11-1511-4022",
    displayNumber: "+60-11-1511-4022",
    formattedCallNumber: "+601115114022",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malaysia",
    countryCode: "MY",
    name: "PT Foundation",
    description: "For immediate mental health and crisis support",
    url: "https://www.facebook.com/ptfmalaysia/",
    displayUrl: "www.facebook.com/ptfmalaysia/",
    phone: "03-27876005",
    displayNumber: "03-27876005",
    formattedCallNumber: "+600327876005",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Maldives",
    countryCode: "MV",
    name: "Psychological Support Helpline",
    description: "For students who need mental health and crisis support",
    url: "https://www.moe.gov.mv/en/article/view/898/1484",
    displayUrl: "www.moe.gov.mv/en/article/view/898/1484",
    phone: "1484",
    displayNumber: "1484",
    formattedCallNumber: "+9601484",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Malta",
    countryCode: "MT",
    name: "Supportline 179",
    description: "For immediate mental health and crisis support",
    url: "https://www.stopviolence.gov.mt/support-line-179/#:~:text=The%20support%20line%20179%20is,both%20landline%20and%20mobile%20phones.",
    displayUrl: "https://www.stopviolence.gov.mt/support-line-179/#:~:text=The%20support%20line%20179%20is,both%20landline%20and%20mobile%20phones.",
    phone: "179",
    displayNumber: "179",
    formattedCallNumber: "+356179",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Mauritius",
    countryCode: "MU",
    name: "Befrienders Mauritius",
    description: "For individuals seeking mental health support",
    url: "http://www.befriendersmauritius.com/",
    displayUrl: "www.befriendersmauritius.com/",
    phone: "2308009393",
    displayNumber: "230-800-939-3",
    formattedCallNumber: "+2308009393",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Mexico",
    countryCode: "MX",
    name: "Línea de la Vida",
    description: "Una línea de ayuda patrocinada por el gobierno que provee apoyo para varios problemas, incluyendo salud mental, adicción y problemas familiares.",
    url: "https://www.gob.mx/salud/conadic/acciones-y-programas/centro-de-atencion-ciudadana-contra-las-adicciones-134381",
    displayUrl: "https://www.gob.mx/salud/conadic/acciones-y-programas/centro-de-atencion-ciudadana-contra-las-adicciones-134381",
    phone: "800 911 2000",
    displayNumber: "800 911 2000",
    formattedCallNumber: "+528009112000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Mexico",
    countryCode: "MX",
    name: "The Trevor Project Mexico",
    description: "Para individuos LGBTQI+ buscando apoyo emocional",
    url: "https://www.thetrevorproject.mx/ayuda/",
    displayUrl: "www.thetrevorproject.mx/ayuda/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "chat, text",
    identity: "LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: false,
  },
  {
    countryI18nKey: "Mexico",
    countryCode: "MX",
    name: "National Commision For Addiction",
    description: "Para individuos que buscan apoyo con el consumo de sustancias",
    url: "https://www.gob.mx/salud/conadic/acciones-y-programas/centro-de-atencion-ciudadana-contra-las-adicciones-134381",
    displayUrl: "www.gob.mx/salud/conadic/acciones-y-programas/centro-de-atencion-ciudadana-contra-las-adicciones-134381",
    phone: "8009112000",
    displayNumber: "800-911-200-0",
    formattedCallNumber: "+528009112000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "Moldova",
    countryCode: "MD",
    name: "Telefonul Copilului",
    description: "For youth seeking mental health support",
    url: "https://telefonulcopilului.md/",
    displayUrl: "",
    phone: "116111",
    displayNumber: "116111",
    formattedCallNumber: "+373116111",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Moldova",
    countryCode: "MD",
    name: "Keystone Moldova",
    description: "For people living with a disability",
    url: "https://www.keystonemoldova.md/en/directions-of-activity/hotline-support-service/",
    displayUrl: "https://www.keystonemoldova.md/en/directions-of-activity/hotline-support-service/",
    phone: "08001 0808",
    displayNumber: "08001-0808",
    formattedCallNumber: "+3738001 0808",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Monaco",
    countryCode: "MC",
    name: "Un numéro d'information pour les victimes de violence",
    description: "For victims of violence",
    url: "https://t.ly/pc30C",
    displayUrl: "www.t.ly/pc30C",
    phone: "0800 91 90 10",
    displayNumber: "0800-91-90-10",
    formattedCallNumber: "+377800919010",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Mongolia",
    countryCode: "MN",
    name: "Mental Health Hotline",
    description: "For individuals seeking mental health support",
    url: "",
    displayUrl: "",
    phone: "1800-2000",
    displayNumber: "1800-2000",
    formattedCallNumber: "+97618002000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Mozambique",
    countryCode: "MZ",
    name: "Linha Fala Criança",
    description: "For youth seeking mental health support",
    url: "https://linhafala.org.mz/contactos/",
    displayUrl: "",
    phone: "116",
    displayNumber: "116",
    formattedCallNumber: "+258116",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Namibia",
    countryCode: "NA",
    name: "Childline Namibia",
    description: "For youth seeking mental health support",
    url: "https://www.lifelinechildline.org.na/index.html",
    displayUrl: "www.lifelinechildline.org.na/index.html",
    phone: "116",
    displayNumber: "116",
    formattedCallNumber: "+264116",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Namibia",
    countryCode: "NA",
    name: "Lifeline Namibia",
    description: "For victims of violence",
    url: "https://www.lifelinechildline.org.na/index.html",
    displayUrl: "www.lifelinechildline.org.na/index.html",
    phone: "106",
    displayNumber: "106",
    formattedCallNumber: "+264106",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Namibia",
    countryCode: "NA",
    name: "Lifeline Namibia",
    description: "For individuals in crisis",
    url: "https://www.lifelinechildline.org.na/index.html",
    displayUrl: "www.lifelinechildline.org.na/index.html",
    phone: "+264 61 226 889",
    displayNumber: "+264-61-226-889",
    formattedCallNumber: "+26461226889",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Nepal",
    countryCode: "NP",
    name: "National helpline for Suicide Prevention ",
    description: "For individuals in crisis",
    url: "https://www.facebook.com/1166helpline",
    displayUrl: "https://www.facebook.com/1166helpline",
    phone: "1166",
    displayNumber: "1166",
    formattedCallNumber: "1166",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Nepal",
    countryCode: "NP",
    name: "Patan Hospital Helpline",
    description: "For individuals in crisis",
    url: "",
    displayUrl: "",
    phone: "9813476123",
    displayNumber: "9813476123",
    formattedCallNumber: "+9779813476123",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Netherlands",
    countryCode: "NL",
    name: "113 Suicide Prevention",
    description: "For individuals in crisis",
    url: "https://www.113.nl/english",
    displayUrl: "www.113.nl/english",
    phone: "113",
    displayNumber: "113",
    formattedCallNumber: "+31113",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Netherlands",
    countryCode: "NL",
    name: "Moviera",
    description: "For individuals experiencing domestic violence",
    url: "https://www.moviera.nl/",
    displayUrl: "www.moviera.nl/",
    phone: "0883744744",
    displayNumber: "088-374-474-4",
    formattedCallNumber: "+310883744744",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Netherlands",
    countryCode: "NL",
    name: "De luisterlijn",
    description: "For adults who need a listening ear",
    url: "https://www.deluisterlijn.nl/",
    displayUrl: "www.deluisterlijn.nl/",
    phone: "0880767000",
    displayNumber: "088-076-700-0",
    formattedCallNumber: "+310880767000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Netherlands",
    countryCode: "NL",
    name: "MIND Korrelatie",
    description: "For individuals seeking psychological and psychosocial help",
    url: "https://mindkorrelatie.nl/",
    displayUrl: "www.mindkorrelatie.nl/",
    phone: "09001450",
    displayNumber: "090-014-50",
    formattedCallNumber: "+3109001450",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Netherlands",
    countryCode: "NL",
    name: "Kindertelefoon",
    description: "For youth looking for mental health support",
    url: "https://www.kindertelefoon.nl/",
    displayUrl: "www.kindertelefoon.nl/",
    phone: "08000432",
    displayNumber: "080-004-32",
    formattedCallNumber: "+3108000432",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Lifeline Suicide Crisis Helpline",
    description: "For individuals in crisis",
    url: "https://www.lifeline.org.nz/",
    displayUrl: "www.lifeline.org.nz/",
    phone: "0508828865",
    displayNumber: "050-882-886-5",
    formattedCallNumber: "+640508828865",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Youthline",
    description: "For youth seeking mental health support",
    url: "https://www.youthline.co.nz/",
    displayUrl: "www.youthline.co.nz/",
    phone: "0800376633",
    displayNumber: "080-037-663-3",
    formattedCallNumber: "+640800376633",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Samaritans",
    description: "For individuals seeking mental health support",
    url: "https://www.samaritans.org.nz/",
    displayUrl: "www.samaritans.org.nz/",
    phone: "0800726666",
    displayNumber: "080-072-666-6",
    formattedCallNumber: "+640800726666",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Depression Hotline",
    description: "For individuals struggling with anxiety and depression",
    url: "https://www.depression.org.nz/",
    displayUrl: "www.depression.org.nz/",
    phone: "0800111757",
    displayNumber: "080-011-175-7",
    formattedCallNumber: "+640800111757",
    formattedTextingNumber: "+644202",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Whats Up",
    description: "For youth seeking mental health support",
    url: "https://whatsup.co.nz/",
    displayUrl: "www.whatsup.co.nz/",
    phone: "08009428787",
    displayNumber: "080-094-287-87",
    formattedCallNumber: "+6408009428787",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Outline",
    description: "For LGBTQI+ seeking support",
    url: "https://outline.org.nz/",
    displayUrl: "www.outline.org.nz/",
    phone: "08006885463",
    displayNumber: "080-068-854-63",
    formattedCallNumber: "+6408006885463",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Family Violence Information Line (are you okay)",
    description: "For individuals struggling with abuse in their relationships",
    url: "https://www.areyouok.org.nz/",
    displayUrl: "www.areyouok.org.nz/",
    phone: "0800456450",
    displayNumber: "080-045-645-0",
    formattedCallNumber: "+640800456450",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Gambling Helpline",
    description: "For individuals struggling with a gambling addiction",
    url: "https://gamblinghelpline.co.nz/",
    displayUrl: "www.gamblinghelpline.co.nz/",
    phone: "0800654655",
    displayNumber: "080-065-465-5",
    formattedCallNumber: "+640800654655",
    formattedTextingNumber: "+648006",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Alcohol Drug Helpline",
    description: "For individuals struggling with alcohol and drug addiction",
    url: "https://alcoholdrughelp.org.nz/",
    displayUrl: "www.alcoholdrughelp.org.nz/",
    phone: "0800787797",
    displayNumber: "080-078-779-7",
    formattedCallNumber: "+640800787797",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Safe to talk",
    description: "For individuals who have experienced sexual harm",
    url: "https://safetotalk.nz/",
    displayUrl: "www.safetotalk.nz/",
    phone: "0800044334",
    displayNumber: "080-004-433-4",
    formattedCallNumber: "+640800044334",
    formattedTextingNumber: "+644334",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "Quitline",
    description: "For individuals struggling with a smoking addiction",
    url: "https://quit.org.nz/",
    displayUrl: "www.quit.org.nz/",
    phone: "0800778778",
    displayNumber: "080-077-877-8",
    formattedCallNumber: "+640800778778",
    formattedTextingNumber: "+644006",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "NewZealand",
    countryCode: "NZ",
    name: "EDANZ",
    description: "For individuals struggling with an eating disorder",
    url: "https://www.ed.org.nz/need-help-now",
    displayUrl: "www.ed.org.nz/need-help-now",
    phone: "0800233269",
    displayNumber: "080-023-3269",
    formattedCallNumber: "+640800233269",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: true,
  },
  {
    countryI18nKey: "Nigeria",
    countryCode: "NG",
    name: "Nigeria Suicide Prevention",
    description: "For immediate mental health and crisis support",
    url: "https://nigeriasuicideprevention.com/",
    displayUrl: "www.nigeriasuicideprevention.com/",
    phone: "+23419125106",
    displayNumber: "234-191-251-06",
    formattedCallNumber: "+23419125106",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Nigeria",
    countryCode: "NG",
    name: "Mentally Aware Nigeria Initiative",
    description: "For youth seeking mental health support",
    url: "https://www.facebook.com/mentallyawareng/",
    displayUrl: "https://www.facebook.com/mentallyawareng/",
    phone: "08091116264",
    displayNumber: "080-911-162-64",
    formattedCallNumber: "+23408091116264",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Nigeria",
    countryCode: "NG",
    name: "Lagos Mind",
    description: "For individuals seeking mental health support",
    url: "https://lagosmind.org/",
    displayUrl: "www.lagosmind.org/",
    phone: "+2349090006463",
    displayNumber: "234-909-000-646-3",
    formattedCallNumber: "+2349090006463",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Nigeria",
    countryCode: "NG",
    name: "Cece Ayara Foundation",
    description: "For youth struggling with abuse",
    url: "https://ceceyara.org/",
    displayUrl: "www.ceceyara.org/",
    phone: "08008008001",
    displayNumber: "080-080-080-01",
    formattedCallNumber: "+23408008008001",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "NorthMacedonia",
    countryCode: "MK",
    name: "Alo Bushavko",
    description: "For youth seeking mental health support",
    url: "https://alobushavko.mk/mk/",
    displayUrl: "www.alobushavko.mk/mk/",
    phone: "+389 70 390 632",
    displayNumber: "+389-70-390-632",
    formattedCallNumber: "+38970390632",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "NorthMacedonia",
    countryCode: "MK",
    name: "Krizen Centar",
    description: "For victims of domestic violence",
    url: "www.krizencentar.org.mk/",
    displayUrl: "https://krizencentar.org.mk/",
    phone: "15-315",
    displayNumber: "15-315",
    formattedCallNumber: "+38915315",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Norway",
    countryCode: "NO",
    name: "Kirkens SOS",
    description: "For individuals in crisis",
    url: "https://www.kirkens-sos.no/",
    displayUrl: "www.kirkens-sos.no/",
    phone: "22400040",
    displayNumber: "224-000-40",
    formattedCallNumber: "+4722400040",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Norway",
    countryCode: "NO",
    name: "Mental Helse Hjelpetelefonen",
    description: "For individuals in crisis",
    url: "https://mentalhelse.no/",
    displayUrl: "www.mentalhelse.no/",
    phone: "116123",
    displayNumber: "116-123",
    formattedCallNumber: "+47116123",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Norway",
    countryCode: "NO",
    name: "Ungdomstelefonen",
    description: "For LGBTQI+ youth",
    url: "https://ungdomstelefonen.no/",
    displayUrl: "www.ungdomstelefonen.no/",
    phone: "40000777",
    displayNumber: "400-007-77",
    formattedCallNumber: "+4740000777",
    formattedTextingNumber: "+4740000777",
    availability: "",
    modality: "phone, chat, text",
    identity: "youth, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Norway",
    countryCode: "NO",
    name: "116 111 alarmtelefonen",
    description: "For youth struggling with abuse",
    url: "https://www.116111.no/",
    displayUrl: "www.116111.no/",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+47116111",
    formattedTextingNumber: "+4741716111",
    availability: "24/7",
    modality: "phone, chat, text",
    identity: "youth",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Norway",
    countryCode: "NO",
    name: "Kors på halsen",
    description: "For youth seeking mental health support",
    url: "https://rodekors.service-now.com/x/tnrc/korspahalsen/index",
    displayUrl: "www.rodekors.service-now.com/x/tnrc/korspahalsen/index",
    phone: "80033321",
    displayNumber: "800-333-21",
    formattedCallNumber: "+4780033321",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, text",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Norway",
    countryCode: "NO",
    name: "rusinfo",
    description: "For individuals struggling with substance abuse",
    url: "https://rusinfo.no/",
    displayUrl: "www.rusinfo.no/",
    phone: "91508588",
    displayNumber: "915-085-88",
    formattedCallNumber: "+4791508588",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Oman",
    countryCode: "OM",
    name: "The psychological support call center for emergency situations",
    description: "",
    url: "",
    displayUrl: "",
    phone: "77 788 800",
    displayNumber: "77-788-800",
    formattedCallNumber: "+96877788800",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Pakistan",
    countryCode: "PK",
    name: "UMANG",
    description: "",
    url: "https://www.umang.com.pk/",
    displayUrl: "www.umang.com.pk/",
    phone: "(92) 0311 7786264",
    displayNumber: "0311-7786264",
    formattedCallNumber: "+9203117786264",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Palestine",
    countryCode: "PS",
    name: "SAWA 121",
    description: "",
    url: "www.sawa.ps/",
    displayUrl: "www.sawa.ps/",
    phone: "121",
    displayNumber: "121",
    formattedCallNumber: "+970121",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Panama",
    countryCode: "PA",
    name: "The Child Protection Line",
    description: "",
    url: "https://senniaf.gob.pa/?page_id=16121",
    displayUrl: "www.senniaf.gob.pa/?page_id=16121",
    phone: "6378-3466",
    displayNumber: "6378-3466",
    formattedCallNumber: "+50763783466",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "PapuaNewGuinea",
    countryCode: "PG",
    name: "1Tok Kaunselin Helpim Lain",
    description: "For victims of family and sexual violence",
    url: "https://www.facebook.com/1TokHelpimLain",
    displayUrl: "www.facebook.com/1TokHelpimLain",
    phone: "+675 7150 8000",
    displayNumber: "+675-7150-8000",
    formattedCallNumber: "+67571508000",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Paraguay",
    countryCode: "PY",
    name: "Fono Ayuda",
    description: "para jóvenes",
    url: "https://www.minna.gov.py/pagina/1224-fono-ayuda-147.html",
    displayUrl: "www.minna.gov.py/pagina/1224-fono-ayuda-147.html",
    phone: "147",
    displayNumber: "147",
    formattedCallNumber: "+595147",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Peru",
    countryCode: "PE",
    name: "La Voz Amiga",
    description: "Para individuos que buscan apoyo en salud mental",
    url: "http://www.lavozamigaperu.org/",
    displayUrl: "www.lavozamigaperu.org/",
    phone: "080041212",
    displayNumber: "080-041-212",
    formattedCallNumber: "+51080041212",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Peru",
    countryCode: "PE",
    name: "Línea nacional de servicios de salud",
    description: "Para individuos que buscan apoyo en salud mental",
    url: "https://www.gob.pe/555-recibir-informacion-y-orientacion-en-salud",
    displayUrl: "www.gob.pe/555-recibir-informacion-y-orientacion-en-salud",
    phone: "113",
    displayNumber: "113",
    formattedCallNumber: "+51113",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Peru",
    countryCode: "PE",
    name: "Línea nacional para la violencia contra las mujeres y miembros del grupo familiar",
    description: "Para mujeres y miembros de grupos familiares experimentando abuso",
    url: "https://www.gob.pe/479-reportar-casos-de-violencia-contra-las-mujeres-e-integrantes-del-grupo-familiar",
    displayUrl: "www.gob.pe/479-reportar-casos-de-violencia-contra-las-mujeres-e-integrantes-del-grupo-familiar",
    phone: "100",
    displayNumber: "100",
    formattedCallNumber: "+51100",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Philippines",
    countryCode: "PH",
    name: "NCMH Crisis Hotline",
    description: "",
    url: "https://www.facebook.com/ncmhcrisishotline/",
    displayUrl: "www.facebook.com/ncmhcrisishotline/",
    phone: "1553",
    displayNumber: "1553",
    formattedCallNumber: "1553",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Poland",
    countryCode: "PL",
    name: "Centrum wsparcia dla osób dorosłych w kryzysie psychicznym",
    description: "For adults in crisis",
    url: "https://centrumwsparcia.pl/",
    displayUrl: "www.centrumwsparcia.pl/",
    phone: "800702222",
    displayNumber: "800-702-222",
    formattedCallNumber: "+48800702222",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Poland",
    countryCode: "PL",
    name: "Olsztyński Telefon Zaufania",
    description: "For individuals in crisis",
    url: "http://telefonzaufania.org/",
    displayUrl: "www.telefonzaufania.org/",
    phone: "19288",
    displayNumber: "891-928-8",
    formattedCallNumber: "+488919288",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Poland",
    countryCode: "PL",
    name: "Stowarzyszenie animo",
    description: "Only for individuals who struggle with anxiety",
    url: "https://stowarzyszenieanimo.pl/",
    displayUrl: "www.stowarzyszenieanimo.pl/",
    phone: "(22) 270 11 65",
    displayNumber: "222-701-165",
    formattedCallNumber: "+48222701165",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Poland",
    countryCode: "PL",
    name: "Centrum Praw Kobiet",
    description: "For women in crisis",
    url: "https://cpk.org.pl/pomoc/pomoc-telefoniczna/",
    displayUrl: "www.cpk.org.pl/pomoc/pomoc-telefoniczna/",
    phone: "800 107 777",
    displayNumber: "800-107-777",
    formattedCallNumber: "+48800107777",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, email",
    identity: "women",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Portugal",
    countryCode: "PT",
    name: "Voz de Apoio",
    description: "For individuals seeking mental health support",
    url: "http://www.vozdeapoio.pt/",
    displayUrl: "www.vozdeapoio.pt/",
    phone: "(+351) 225 50 60 70",
    displayNumber: "351-225-506-070",
    formattedCallNumber: "+351225506070",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Portugal",
    countryCode: "PT",
    name: "Vozes Amigas de esperanca (VOADES)",
    description: "For individuals seeking mental health support",
    url: "https://www.voades.pt/",
    displayUrl: "www.voades.pt/",
    phone: "222 030 707",
    displayNumber: "222-030-707",
    formattedCallNumber: "+351222030707",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Portugal",
    countryCode: "PT",
    name: "Telefon de Amizade",
    description: "For individuals seeking mental health support",
    url: "http://www.telefone-amizade.pt/site/",
    displayUrl: "www.telefone-amizade.pt/site/",
    phone: "22 832 35 35",
    displayNumber: "228-323-535",
    formattedCallNumber: "+351228323535",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Portugal",
    countryCode: "PT",
    name: "SOS estudante",
    description: "For students seeking mental health support",
    url: "http://sosestudante.pt/",
    displayUrl: "www.sosestudante.pt/",
    phone: "915246060",
    displayNumber: "915-246-060",
    formattedCallNumber: "+351915246060",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Portugal",
    countryCode: "PT",
    name: "SOS voz amiga",
    description: "For individuals seeking mental health support",
    url: "https://www.sosvozamiga.org/",
    displayUrl: "www.sosvozamiga.org/",
    phone: "213 544 545",
    displayNumber: "213-544-545",
    formattedCallNumber: "+351213544545",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Portugal",
    countryCode: "PT",
    name: "Comissão para a Cidadania e Igualdade de Género (CIG)",
    description: "For individuals experiencing domestic violence",
    url: "https://www.cig.gov.pt/area-portal-da-violencia/portal-violencia-domestica/apoio-vitimas-violencia-domestica/",
    displayUrl: "www.cig.gov.pt/area-portal-da-violencia/portal-violencia-domestica/apoio-vitimas-violencia-domestica/",
    phone: "800 202 148",
    displayNumber: "800-202-148",
    formattedCallNumber: "+351800202148",
    formattedTextingNumber: "+3513060",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "PuertoRico",
    countryCode: "PR",
    name: "ASSMCA Línea PAS",
    description: "Para individuos que buscan apoyo en salud mental",
    url: "https://lineapas.assmca.pr.gov/",
    displayUrl: "www.lineapas.assmca.pr.gov/",
    phone: "1 (800) 981-0023",
    displayNumber: "180-098-100-23",
    formattedCallNumber: "+18009810023",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "PuertoRico",
    countryCode: "PR",
    name: "Proyecto Matria",
    description: "Para mujeres en situaciones de violencia de género",
    url: "https://www.proyectomatria.org/",
    displayUrl: "www.proyectomatria.org/",
    phone: "787-489-0022",
    displayNumber: "787-489-002-2",
    formattedCallNumber: "+17874890022",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Qatar",
    countryCode: "QA",
    name: "Protection and Social Rehabilitation Centre (AMAN Centre)",
    description: "For immediate mental health and crisis support",
    url: "https://www.aman.org.qa/en/news/seashore-group-919-hotline-strategic-partner-community-partnership-between-aman-center-and",
    displayUrl: "www.aman.org.qa/en/news/seashore-group-919-hotline-strategic-partner-community-partnership-between-aman-center-and",
    phone: "919",
    displayNumber: "919",
    formattedCallNumber: "919",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Romania",
    countryCode: "RO",
    name: "Deprehub",
    description: "For immediate mental health and crisis support",
    url: "https://deprehub.ro/",
    displayUrl: "www.deprehub.ro/",
    phone: "0374-456-420",
    displayNumber: "0374-456-420",
    formattedCallNumber: "+40374456420",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Romania",
    countryCode: "RO",
    name: "Antisuicid",
    description: "For immediate mental health and crisis support",
    url: "https://www.antisuicid.ro/",
    displayUrl: "www.antisuicid.ro/",
    phone: "0800-801-200",
    displayNumber: "0800-801-200",
    formattedCallNumber: "+40800801200",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Rwanda",
    countryCode: "RW",
    name: "Mental Health First",
    description: "For immediate mental health and crisis support",
    url: "https://mentalhealth-first.org/",
    displayUrl: "www.mentalhealth-first.org/",
    phone: "+250788561101",
    displayNumber: "+250-788-561-101",
    formattedCallNumber: "+250788561101",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SaintLucia",
    countryCode: "LC",
    name: "Saint Lucia National Health Helpline",
    description: "For immediate mental health and crisis support",
    url: "http://www.govt.lc/news/national-health-helpline-launched",
    displayUrl: "www.govt.lc/news/national-health-helpline-launched",
    phone: "203",
    displayNumber: "203",
    formattedCallNumber: "203",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Samoa",
    countryCode: "WS",
    name: "Samoa Victim Support Group (SVSG)",
    description: "For immediate mental health and crisis support",
    url: "https://svsg.org.ws/",
    displayUrl: "www.svsg.org.ws/",
    phone: "8007874",
    displayNumber: "800-7874",
    formattedCallNumber: "800-7875",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SaudiArabia",
    countryCode: "SA",
    name: "National Center for Mental Health Promotion",
    description: "For immediate mental health and crisis support",
    url: "",
    displayUrl: "",
    phone: "920033360",
    displayNumber: "9200-33360",
    formattedCallNumber: "+966920033360",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Serbia",
    countryCode: "RS",
    name: "Centar SRCE",
    description: "For individuals in crisis",
    url: "https://www.centarsrce.org/en/index.php",
    displayUrl: "www.centarsrce.org/en/index.php",
    phone: "0800-300-303",
    displayNumber: "080-030-030-3",
    formattedCallNumber: "+3810800300303",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Serbia",
    countryCode: "RS",
    name: "NADEL",
    description: "For youth in crisis",
    url: "https://www.116111.rs/o-nadelu.html",
    displayUrl: "www.116111.rs/o-nadelu.html",
    phone: "116111",
    displayNumber: "116-111",
    formattedCallNumber: "+381116111",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Seychelles",
    countryCode: "SC",
    name: "Quality of Life Division Helpline ",
    description: "For individuals seeking mental health support",
    url: "https://qld-counselling.sc/",
    displayUrl: "www.qld-counselling.sc/",
    phone: "2722292",
    displayNumber: "2722292",
    formattedCallNumber: "2722292",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Singapore",
    countryCode: "SG",
    name: "Samaritans of Singapore (SOS)",
    description: "For individuals in crisis",
    url: "https://www.sos.org.sg/",
    displayUrl: "www.sos.org.sg/",
    phone: "1767",
    displayNumber: "176-7",
    formattedCallNumber: "+651767",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Singapore",
    countryCode: "SG",
    name: "Sexual assault care centre | AWARE",
    description: "For individuals who have experienced sexual harm",
    url: "https://sacc.aware.org.sg/",
    displayUrl: "www.sacc.aware.org.sg/",
    phone: "6779 0282",
    displayNumber: "677-902-82",
    formattedCallNumber: "+6567790282",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, email",
    identity: "women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "Singapore",
    countryCode: "SG",
    name: "AWARE | Women's Helpline",
    description: "For women seeking mental health support",
    url: "https://www.aware.org.sg/womens-care-centre/helpline/",
    displayUrl: "www.aware.org.sg/womens-care-centre/helpline/",
    phone: "1800 777 5555",
    displayNumber: "180-077-755-55",
    formattedCallNumber: "+6518007775555",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "women",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Slovakia",
    countryCode: "SK",
    name: "Linka dôvery Nezábudka",
    description: "For individuals in crisis",
    url: "https://www.linkanezabudka.sk/",
    displayUrl: "www.linkanezabudka.sk/",
    phone: "0800-800-566",
    displayNumber: "0800-800-566",
    formattedCallNumber: "+421800800566",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Slovenia",
    countryCode: "SI",
    name: "Telefon Samarijan",
    description: "For individuals in crisis",
    url: "http://www.telefon-samarijan.si/",
    displayUrl: "www.telefon-samarijan.si/",
    phone: "116 123",
    displayNumber: "116-123",
    formattedCallNumber: "+386116123",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Slovenia",
    countryCode: "SI",
    name: "TOM – telefon za otroke in mladostnike - TOM - Telephone For Children And Adolescents",
    description: "For youth in crisis",
    url: "https://e-tom.si/",
    displayUrl: "www.e-tom.si/",
    phone: "116-111",
    displayNumber: "116-111",
    formattedCallNumber: "+386116111",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Slovenia",
    countryCode: "SI",
    name: "SOS telefon za ženske in otroke - žrtve nasilja",
    description: "For women and children victims",
    url: "https://drustvo-sos.si/",
    displayUrl: "www.drustvo-sos.si/",
    phone: "080 11 55",
    displayNumber: "080-11-55",
    formattedCallNumber: "+3860801155",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "The South African Depression and Anxiety Group (SADAG)",
    description: "For individuals in crisis",
    url: "https://www.sadag.org/",
    displayUrl: "www.sadag.org/",
    phone: "0800 567 567",
    displayNumber: "080-056-756-7",
    formattedCallNumber: "+270800567567",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "Childline",
    description: "For youth in crisis",
    url: "https://www.childlinesa.org.za/",
    displayUrl: "www.childlinesa.org.za/",
    phone: "116",
    displayNumber: "116",
    formattedCallNumber: "+27116",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "Netcare akeso",
    description: "For individuals seeking mental health support",
    url: "https://www.netcare.co.za/netcare-akeso",
    displayUrl: "www.netcare.co.za/netcare-akeso",
    phone: "0861 435 787",
    displayNumber: "086-143-578-7",
    formattedCallNumber: "+270861435787",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "Lifeline",
    description: "For individuals seeking mental health support",
    url: "https://lifelinesa.co.za/",
    displayUrl: "www.lifelinesa.co.za/",
    phone: "0861 322 322",
    displayNumber: "086-132-232-2",
    formattedCallNumber: "+270861322322",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "Lifeline AIDS Helpline",
    description: "For HIV/AIDS positive individuals seeking support",
    url: "https://lifelinesa.co.za/",
    displayUrl: "www.lifelinesa.co.za/",
    phone: "0800 012 322",
    displayNumber: "080-001-232-2",
    formattedCallNumber: "+270800012322",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "Lifeline Gender Violence Helpline",
    description: "For individuals in abusive situations",
    url: "https://lifelinesa.co.za/",
    displayUrl: "www.lifelinesa.co.za/",
    phone: "0800 150 150",
    displayNumber: "080-015-015-0",
    formattedCallNumber: "+270800150150",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthAfrica",
    countryCode: "ZA",
    name: "Alcoholics Anonymous South Africa",
    description: "For individuals struggling with alcohol addiction",
    url: "https://www.aasouthafrica.org.za/",
    displayUrl: "www.aasouthafrica.org.za/",
    phone: "0861 435 722",
    displayNumber: "086-143-572-2",
    formattedCallNumber: "+270861435722",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthKorea",
    countryCode: "KR",
    name: "보건복지상담센터",
    description: "For individuals in crisis",
    url: "",
    displayUrl: "",
    phone: "1393",
    displayNumber: "139-3",
    formattedCallNumber: "+821393",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthKorea",
    countryCode: "KR",
    name: "청소년상담1388 | Cyber1388",
    description: "For youth seeking mental health support",
    url: "https://www.1388.go.kr/ind/YTOSP_SC_IND_01",
    displayUrl: "https://www.1388.go.kr/ind/YTOSP_SC_IND_01",
    phone: "1388",
    displayNumber: "1388",
    formattedCallNumber: "+821388",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthKorea",
    countryCode: "KR",
    name: "여성폭력 상담",
    description: "For women in situations of gender violence",
    url: "https://women1366.kr/?menuno=222",
    displayUrl: "www.women1366.kr/?menuno=222",
    phone: "1366",
    displayNumber: "1366",
    formattedCallNumber: "+821366",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "women",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthKorea",
    countryCode: "KR",
    name: "Counsel 24",
    description: "For individuals seeking mental health support",
    url: "https://www.counsel24.com/",
    displayUrl: "www.counsel24.com/",
    phone: "1566 2525",
    displayNumber: "1566-2525",
    formattedCallNumber: "+8215662525",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SouthKorea",
    countryCode: "KR",
    name: "Suicide Hotline",
    description: "For individuals seeking mental health support",
    url: "https://www.lifeline.or.kr/index.php",
    displayUrl: "www.lifeline.or.kr/index.php",
    phone: "1588-9191",
    displayNumber: "1588-9191",
    formattedCallNumber: "+8215889191",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Spain",
    countryCode: "ES",
    name: "Línea de Crisis por Suicidio",
    description: "Para individuos en crisis",
    url: "https://www.sanidad.gob.es/linea024/home.htm",
    displayUrl: "www.sanidad.gob.es/linea024/home.htm",
    phone: "024",
    displayNumber: "024",
    formattedCallNumber: "+34024",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Spain",
    countryCode: "ES",
    name: "Teléfono de la Mujer",
    description: "Para mujeres ",
    url: "https://igualdade.xunta.gal/es/content/telefono-de-la-mujer",
    displayUrl: "www.igualdade.xunta.gal/es/content/telefono-de-la-mujer",
    phone: "900 400 273",
    displayNumber: "900-400-273",
    formattedCallNumber: "+34900400273",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Spain",
    countryCode: "ES",
    name: "Samaritans in Spain",
    description: "Para individuos que buscan apoyo en salud mental",
    url: "https://www.samaritansinspain.com/",
    displayUrl: "www.samaritansinspain.com/",
    phone: "900 525 100",
    displayNumber: "900-525-100",
    formattedCallNumber: "+34900525100",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Spain",
    countryCode: "ES",
    name: "Telefono de la Esperanza",
    description: "For individuals seeking mental health support",
    url: "https://telefonodelaesperanza.org/",
    displayUrl: "www.telefonodelaesperanza.org/",
    phone: "717 003 717",
    displayNumber: "717-003-717",
    formattedCallNumber: "+34717003717",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "SriLanka",
    countryCode: "LK",
    name: "Sri Lanka Sumithrayo (SLS) Colombo",
    description: "For individuals seeking mental health support",
    url: "https://srilankasumithrayo.lk/",
    displayUrl: "www.srilankasumithrayo.lk/",
    phone: "0707 308 308",
    displayNumber: "070-730-830-8",
    formattedCallNumber: "+940707308308",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Sweden",
    countryCode: "SE",
    name: "Krykans SOS",
    description: "For youth seeking mental health support",
    url: "https://kyrkanssos.se/hitta-hjalp/natvandrarchatten/",
    displayUrl: "www.kyrkanssos.se/hitta-hjalp/natvandrarchatten/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Sweden",
    countryCode: "SE",
    name: "Spes stödlinje för efterlevande till suicidförlust",
    description: "For survivors of suicide loss",
    url: "https://spes.se/",
    displayUrl: "www.spes.se/",
    phone: "020 -18 18 00",
    displayNumber: "020-181-800",
    formattedCallNumber: "+46020181800",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Sweden",
    countryCode: "SE",
    name: "Mind",
    description: "For individuals in crisis",
    url: "https://mind.se/",
    displayUrl: "www.mind.se/",
    phone: "90 101",
    displayNumber: "901-01",
    formattedCallNumber: "+4690101",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Switzerland",
    countryCode: "CH",
    name: "143",
    description: "For individuals in crisis",
    url: "https://www.143.ch/",
    displayUrl: "www.143.ch/",
    phone: "143",
    displayNumber: "143",
    formattedCallNumber: "+41143",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Switzerland",
    countryCode: "CH",
    name: "147",
    description: "For youth in crisis",
    url: "https://www.147.ch/",
    displayUrl: "www.147.ch/",
    phone: "147",
    displayNumber: "147",
    formattedCallNumber: "+41147",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Switzerland",
    countryCode: "CH",
    name: "Paraspas",
    description: "For french speakers in crisis",
    url: "https://www.parspas.ch/",
    displayUrl: "www.parspas.ch/",
    phone: "027 321 21 21",
    displayNumber: "027-321-212-1",
    formattedCallNumber: "+410273212121",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Taiwan",
    countryCode: "TW",
    name: "113 Protection Hotline",
    description: "For youth seeking mental health support",
    url: "https://tinyurl.com/4xeabne3",
    displayUrl: "https://tinyurl.com/4xeabne3",
    phone: "113",
    displayNumber: "113",
    formattedCallNumber: "+886113",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Taiwan",
    countryCode: "TW",
    name: "Teacher Zhang Foundation",
    description: "For individuals seeking mental health support",
    url: "https://www.1980.org.tw/",
    displayUrl: "www.1980.org.tw/",
    phone: "1980",
    displayNumber: "198-0",
    formattedCallNumber: "+8861980",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Taiwan",
    countryCode: "TW",
    name: "Taiwan Lifeline International",
    description: "For individuals seeking mental health support",
    url: "http://www.life1995.org.tw/",
    displayUrl: "www.life1995.org.tw/",
    phone: "1995",
    displayNumber: "1995",
    formattedCallNumber: "+8861995",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Tanzania",
    countryCode: "TZ",
    name: "Sema Tanzania - Child Helpline",
    description: "For youth in crisis",
    url: "https://www.sematanzania.org/child-helpline",
    displayUrl: "www.sematanzania.org/child-helpline",
    phone: "116",
    displayNumber: "116",
    formattedCallNumber: "+255116",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Thailand",
    countryCode: "TH",
    name: "1323 ปรึกษาปัญหาสุขภาพจิต",
    description: "For individuals seeking mental health support",
    url: "https://www.facebook.com/helpline1323",
    displayUrl: "https://www.facebook.com/helpline1324",
    phone: "1323",
    displayNumber: "1323",
    formattedCallNumber: "+661323",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "TrinidadAndTobago",
    countryCode: "TT",
    name: "Lifeline",
    description: "For individuals in crisis",
    url: "https://lifelinett.com/",
    displayUrl: "www.lifelinett.com/",
    phone: "800 5588",
    displayNumber: "800-5588",
    formattedCallNumber: "+18688005588",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "TrinidadAndTobago",
    countryCode: "TT",
    name: "Childline",
    description: "For youth in crisis",
    url: "https://childlinett.org/",
    displayUrl: "www.childlinett.org/",
    phone: "131",
    displayNumber: "131",
    formattedCallNumber: "+186831",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Uganda",
    countryCode: "UG",
    name: "Mental Health Uganda",
    description: "For individuals seeking mental health support",
    url: "https://www.mentalhealthuganda.org/",
    displayUrl: "www.mentalhealthuganda.org/",
    phone: "0800212121",
    displayNumber: "0800-21-2121",
    formattedCallNumber: "+2560800212121",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Ukraine",
    countryCode: "UA",
    name: "Lifeline Ukraine",
    description: "For individuals seeking mental health support",
    url: "https://lifelineukraine.com/",
    displayUrl: "www.lifelineukraine.com/",
    phone: "7333",
    displayNumber: "7333",
    formattedCallNumber: "+3807333",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "UnitedArabEmirates",
    countryCode: "AE",
    name: "The Mental Support Line",
    description: "For individuals seeking mental health support",
    url: "https://hope.hw.gov.ae/",
    displayUrl: "www.hope.hw.gov.ae/",
    phone: "8004673",
    displayNumber: "8004673",
    formattedCallNumber: "+9718004673",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "National Suicide Prevention Helpline",
    description: "For individuals in crisis",
    url: "https://www.spuk.org.uk/national-suicide-prevention-helpline-uk/",
    displayUrl: "www.spuk.org.uk/national-suicide-prevention-helpline-uk/",
    phone: "0800 689 5652",
    displayNumber: "080-068-956-52",
    formattedCallNumber: "+4408006895652",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "HOPELINE 247",
    description: "For youth in crisis",
    url: "https://www.papyrus-uk.org/",
    displayUrl: "www.papyrus-uk.org/",
    phone: "0800 068 4141",
    displayNumber: "080-006-841-41",
    formattedCallNumber: "+4408000684141",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Samaritans UK",
    description: "For individuals seeking mental health support",
    url: "https://www.samaritans.org",
    displayUrl: "www.samaritans.org",
    phone: "116 123",
    displayNumber: "116-123",
    formattedCallNumber: "+44116123",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "CALM (Campaign Against Living Miserably)",
    description: "For individuals in crisis",
    url: "https://www.thecalmzone.net/",
    displayUrl: "www.thecalmzone.net/",
    phone: "0800 58 58 58",
    displayNumber: "080-058-585-8",
    formattedCallNumber: "+440800585858",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "LGBTQ+ Helpline Scotland",
    description: "For LGBTQI+ individuals seeking support",
    url: "https://www.lgbthealth.org.uk/",
    displayUrl: "www.lgbthealth.org.uk/",
    phone: "0800 464 7000",
    displayNumber: "080-046-470-00",
    formattedCallNumber: "+4408004647000",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "National Domestic Abuse Helpline",
    description: "For individuals experiencing domestic abuse",
    url: "https://www.nationaldahelpline.org.uk/",
    displayUrl: "www.nationaldahelpline.org.uk/",
    phone: "0808 2000 247",
    displayNumber: "080-820-002-47",
    formattedCallNumber: "+4408082000247",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Alcoholics Anonymous",
    description: "For individuals struggling with alcohol abuse",
    url: "https://www.alcoholics-anonymous.org.uk/",
    displayUrl: "www.alcoholics-anonymous.org.uk/",
    phone: "0800 9177 650",
    displayNumber: "080-091-776-50",
    formattedCallNumber: "+4408009177650",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Gamble Aware",
    description: "For individuals struggling with a gambling addiction",
    url: "https://www.begambleaware.org/",
    displayUrl: "www.begambleaware.org/",
    phone: "0808 8020 133",
    displayNumber: "080-880-201-33",
    formattedCallNumber: "+4408088020133",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Eating Disorders Support",
    description: "For individuals struggling with an eating disorder",
    url: "https://www.eatingdisorderssupport.co.uk/",
    displayUrl: "www.eatingdisorderssupport.co.uk/",
    phone: "01494 793223",
    displayNumber: "014-947-932-23",
    formattedCallNumber: "+4401494793223",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, email",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Narcotics Anonymous",
    description: "For individuals struggling with substance abuse",
    url: "https://ukna.org/content/need-help",
    displayUrl: "www.ukna.org/content/need-help",
    phone: "0300 999 1212",
    displayNumber: "030-099-912-12",
    formattedCallNumber: "+4403009991212",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "DrugFam",
    description: "For families of those with active addictions",
    url: "https://www.drugfam.co.uk/",
    displayUrl: "www.drugfam.co.uk/",
    phone: "0300 888 3853",
    displayNumber: "030-088-838-53",
    formattedCallNumber: "+4403008883853",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Switchboard LGBTQ+",
    description: "For LGBTQI+ individuals seeking support",
    url: "https://switchboard.lgbt/",
    displayUrl: "www.switchboard.lgbt/",
    phone: "0800 0119 100",
    displayNumber: "080-001-191-00",
    formattedCallNumber: "+4408000119100",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, chat, email",
    identity: "LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Survivors UK",
    description: "For victims of sexual abuse",
    url: "https://www.survivorsuk.org/",
    displayUrl: "www.survivorsuk.org/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "chat, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "BEAT Eating Disorders",
    description: "For individuals struggling with an eating disorder",
    url: "https://www.beateatingdisorders.org.uk",
    displayUrl: "www.beateatingdisorders.org.uk",
    phone: "0808 801 0677",
    displayNumber: "080-880-106-77",
    formattedCallNumber: "+4408088010677",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Rape Crisis Scotland",
    description: "For victims of rape",
    url: "https://www.rapecrisisscotland.org.uk/",
    displayUrl: "www.rapecrisisscotland.org.uk/",
    phone: "08088 01 03 02",
    displayNumber: "080-880-103-02",
    formattedCallNumber: "+4408088010302",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "UnitedKingdom",
    countryCode: "UK",
    name: "Stop It Now!",
    description: "For child victims of sexual abuse",
    url: "https://www.stopitnow.org.uk/",
    displayUrl: "www.stopitnow.org.uk/",
    phone: "0808 1000 900",
    displayNumber: "080-810-009-00",
    formattedCallNumber: "+4408081000900",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "Uruguay",
    countryCode: "UY",
    name: "Línea de prevención del suicidio",
    description: "Para individuos en crisis",
    url: "https://www.gub.uy/ministerio-salud-publica/",
    displayUrl: "www.gub.uy/ministerio-salud-publica/",
    phone: "0800 0767",
    displayNumber: "080-007-67",
    formattedCallNumber: "+59808000767",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
  {
    countryI18nKey: "Uruguay",
    countryCode: "UY",
    name: "Línea Azul",
    description: "Para niños víctimas de abuso",
    url: "https://www.inau.gub.uy/content_page/item/512-linea-azul-denuncias",
    displayUrl: "www.inau.gub.uy/content_page/item/512-linea-azul-denuncias",
    phone: "0800 5050",
    displayNumber: "080-050-50",
    formattedCallNumber: "+59808005050",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: false,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "National Suicide and Crisis Line",
    description: "For immediate mental health and crisis support",
    url: "http://988lifeline.org",
    displayUrl: "www.988lifeline.org",
    phone: "988",
    displayNumber: "988",
    formattedCallNumber: "+1988",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, text",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Friends for Survival",
    description: "For those who are grieving a suicide death",
    url: "https://friendsforsurvival.org/",
    displayUrl: "www.friendsforsurvival.org/",
    phone: "8006467322",
    displayNumber: "800-646-7322",
    formattedCallNumber: "+18006467322",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "The Trevor Project",
    description: "For LGBTQ youth, friends and family members",
    url: "http://www.thetrevorproject.org/",
    displayUrl: "www.thetrevorproject.org/",
    phone: "18664887386",
    displayNumber: "186-648-873-86",
    formattedCallNumber: "+18664887386",
    formattedTextingNumber: "+1678678",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Trans Lifeline",
    description: "For trans and questioning peers",
    url: "https://translifeline.org/hotline/",
    displayUrl: "www.translifeline.org/hotline/",
    phone: "8775658860",
    displayNumber: "877-565-8860",
    formattedCallNumber: "+18775658860",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "LGBT National Helpline",
    description: "For LGBTQIA+ who require support",
    url: "https://lgbthotline.org/national-hotline/",
    displayUrl: "www.lgbthotline.org/national-hotline/",
    phone: "8888434564",
    displayNumber: "888-843-4564",
    formattedCallNumber: "+18888434564",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "LGBT National Coming Out Support Hotline",
    description: "For LGBTQIA+ who require support when coming out",
    url: "https://lgbtcomingout.org/",
    displayUrl: "www.lgbtcomingout.org/",
    phone: "8886885428",
    displayNumber: "888-688-5428",
    formattedCallNumber: "+18886885428",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults, LGBTQ+",
    topic: TOPIC_TYPE.LGBTQ,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "The Veterans Crisis Line",
    description: "For veterans and their loved ones",
    url: "https://www.veteranscrisisline.net/",
    displayUrl: "www.veteranscrisisline.net/",
    phone: "988",
    displayNumber: "988",
    formattedCallNumber: "+1988",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat, text",
    identity: "adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "SAMHSA",
    description: "For individuals struggling with substance abuse",
    url: "https://www.samhsa.gov/find-help/national-helpline",
    displayUrl: "www.samhsa.gov/find-help/national-helpline",
    phone: "18006624357",
    displayNumber: "1800-662-4357",
    formattedCallNumber: "+18006624357",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "National Domestic Violence Helpline",
    description: "For individuals experiencing domestic abuse",
    url: "https://www.thehotline.org/",
    displayUrl: "www.thehotline.org/",
    phone: "18007997233",
    displayNumber: "1800-799-7233",
    formattedCallNumber: "+18007997233",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "TXT 4 HELP",
    description: "For teens in crisis",
    url: "https://www.nationalsafeplace.org/txt-4-help",
    displayUrl: "www.nationalsafeplace.org/txt-4-help",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "44357",
    availability: "24/7",
    modality: "text",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Teen Line",
    description: "For teens in crisis",
    url: "https://www.teenline.org/",
    displayUrl: "www.teenline.org/",
    phone: "8008528336",
    displayNumber: "800-852-8336",
    formattedCallNumber: "+18008528336",
    formattedTextingNumber: "839863",
    availability: "",
    modality: "phone, text",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "National Parent & Youth Helpline",
    description: "For parents, children and youth",
    url: "https://nationalparentyouthhelpline.org/",
    displayUrl: "www.nationalparentyouthhelpline.org/",
    phone: "8554272736",
    displayNumber: "855-427-2736",
    formattedCallNumber: "+18554272736",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "The Youth Line",
    description: "For persons under 18",
    url: "https://www.theyouthline.org/",
    displayUrl: "www.theyouthline.org/",
    phone: "18779688491",
    displayNumber: "187-796-884-91",
    formattedCallNumber: "+18779688491",
    formattedTextingNumber: "+1839863",
    availability: "",
    modality: "phone, text",
    identity: "youth",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Childhelp",
    description: "For child victims of abuse",
    url: "https://www.childhelphotline.org/",
    displayUrl: "www.childhelphotline.org/",
    phone: "8004224453",
    displayNumber: "800-422-4453",
    formattedCallNumber: "+18004224453",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, text",
    identity: "youth",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "National Human Trafficking Hotline",
    description: "For victims of human trafficking",
    url: "https://humantraffickinghotline.org/en",
    displayUrl: "www.humantraffickinghotline.org/en",
    phone: "18883737888",
    displayNumber: "1-888-373-7888",
    formattedCallNumber: "+18883737888",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Alliance for Eating Disorders",
    description: "For persons struggling with disordered eating",
    url: "https://www.allianceforeatingdisorders.com/",
    displayUrl: "www.allianceforeatingdisorders.com/",
    phone: "8666621235",
    displayNumber: "866-662-1235",
    formattedCallNumber: "+18666621235",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "The National Eating Disorder Association",
    description: "For persons struggling with disordered eating",
    url: "https://www.nationaleatingdisorders.org/",
    displayUrl: "www.nationaleatingdisorders.org/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "none",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "ANAD",
    description: "For persons struggling with disordered eating",
    url: "https://anad.org/",
    displayUrl: "www.anad.org/",
    phone: "1 (888)-375-7767",
    displayNumber: "188-837-577-67",
    formattedCallNumber: "+18883757767",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.EATING_DISORDERS,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Stronghearts Native Helpline",
    description: "For victims of domestic and sexual violence",
    url: "https://strongheartshelpline.org/",
    displayUrl: "www.strongheartshelpline.org/",
    phone: "18447628483",
    displayNumber: "1-844-762-8483",
    formattedCallNumber: "18447628483",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "RAINN",
    description: "For victims of sexual assault and their loved ones",
    url: "https://www.rainn.org/",
    displayUrl: "www.rainn.org/",
    phone: "18006564673",
    displayNumber: "180-065-646-73",
    formattedCallNumber: "+18006564673",
    formattedTextingNumber: "",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ABUSE,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "Alcoholics Anonymous",
    description: "For individuals struggling with alcoholism",
    url: "https://www.aa.org/",
    displayUrl: "www.aa.org/",
    phone: "",
    displayNumber: "",
    formattedCallNumber: "",
    formattedTextingNumber: "",
    availability: "",
    modality: "",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "USA",
    countryCode: "US",
    name: "National Council on Problem Gambling",
    description: "For individuals struggling with gambling addiction",
    url: "https://www.ncpgambling.org/help-treatment/national-helpline-1-800-522-4700/",
    displayUrl: "www.ncpgambling.org/help-treatment/national-helpline-1-800-522-4700/",
    phone: "1-800-GAMBLER",
    displayNumber: "1-800-GAMBLER",
    formattedCallNumber: "+18004262537",
    formattedTextingNumber: "+1800426",
    availability: "24/7",
    modality: "phone, chat",
    identity: "youth, adults",
    topic: TOPIC_TYPE.ADDICTION,
    topicMenu: true,
  },
  {
    countryI18nKey: "Venezuela",
    countryCode: "VE",
    name: "Federación Psicólogos de Venezuela",
    description: "Para individuos que buscan apoyo en salud mental",
    url: "",
    displayUrl: "",
    phone: "0212-2564277",
    displayNumber: "021-225-642-77",
    formattedCallNumber: "+5802122564277",
    formattedTextingNumber: "",
    availability: "",
    modality: "phone",
    identity: "youth, adults",
    topic: TOPIC_TYPE.MENTAL_HEALTH,
    topicMenu: false,
  },
];
